import $ from "jquery";

const TITLES = [
  'Сколько лет вашей машинке?',
  'Какая проблема беспокоит?',
  'Ремонтировалась ли она ранее?',
  'Заполните поля и я свяжусь с Вами в ближайшее время:'
];

let currentStep = 1;
const maxSteps = $(".constructor__form-step").length;
const updateStep = () => {
  if(currentStep > 1)
    $('.btn-prev').removeClass('btn-prev--disabled');
  else 
    $('.btn-prev').addClass('btn-prev--disabled');
  
  if(currentStep >= maxSteps) {
    $('.btn-prev').hide();
    $('.btn-next').hide();
    $('.constructor__form-current').hide();
    $('.btn-submit').show();
  }

  $('.constructor__form-current span').text(currentStep);

  $('.constructor__form-step')
    .eq(currentStep - 1)
    .addClass('constructor__form-step--active')
    .siblings()
    .removeClass('constructor__form-step--active');

  $('.constructor__form-title-itself').text(TITLES[currentStep-1]);
}

console.log(maxSteps);

$('.btn-prev').on('click', function(e) {
  e.preventDefault();

  if(currentStep < 2)
    return;
    
  currentStep--;
  updateStep();
});

$('.btn-next').on('click', function(e) {
  e.preventDefault();
  currentStep++;
  updateStep();
});
