import $ from "jquery";

const VIDEO_NODE = $('.about iframe')[0];

$('.about__play').on('click', function(e) {
  $('.about__play').hide();
  $('.about__title').hide();
  $('.about__video-preview').hide();
  VIDEO_NODE.src += "&autoplay=1";
});
