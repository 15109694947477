import "./polyfills/isNan";
import "./polyfills/forEach";

import $ from "jquery";
import "popper.js";
import "bootstrap";

import "./constructor";
import "./about";
import "./mask"
